import { useState, useEffect, forwardRef } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Avatar,
  Stack,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReCAPTCHA from 'react-google-recaptcha';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getAll,
  create
} from '../../redux/business/businessActions';
import {
  validateUsernameExist,
} from '../../redux/auth/authActions';
import helper from '../../helpers/';
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant='filled' {...props} />;
});


const MobileForm = () => {
    const dispatch = useDispatch();
     const [open, setOpen] = useState(false);
      let paramsUrl = useParams();
  const [showPassword, setShowPassword] = useState(false);
   const [validatedUsername, setValidatedUsername] = useState(false);
      const [isUsernameTaken, setIsUsernameTaken] = useState(false);
       const [preview, setPreview] = useState('');
  const [message, setMessage] = useState({
    type: '',
    text: '',
  });
  const [values, setValues] = useState({
    name: '',
    lastName: '',
    position:'',
    email: '',
    mobile: '+1',
    whatsapp: '+1',
    username: '',
    password: '',
    confirmPassword: '',
    avatar: '',
  });
  const [captchaValue, setCaptchaValue] = useState('');


  const handleInputChange = (e) => {
    if(e.target.name === 'username'){
       setValues({ ...values, [e.target.name]: helper.filterString(e.target.value.replace(/\s+/g, ''))})
    }else{
 setValues({
   ...values,
   [e.target.name]:e.target.value
 });
    }
   
  };

  const handleImageChange = (e) => {
      const file = event.target.files[0];
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
    setValues({ ...values, avatar: base64String })
    
    setPreview(reader.result);
   
  };
}

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = {
      values: values,
      id: paramsUrl.id,
    };
    if (captchaValue) {

      if(!values.mobile.match(/^\+(?:[0-9] ?){6,14}[0-9]$/)){
         setMessage({
           type: 'error',
           text: 'El número movil no es válido',
         });
         setOpen(true);
          setMessage({
            type: 'error',
            text: '+18095327575',
          });
          setOpen(true);
         return;
      }

      if (isUsernameTaken || values.username.length < 4) {
        setMessage({
          type: 'error',
          text: 'Debes ingresar un nombre de usuario válido',
        });
        setOpen(true);
        return;
      }

      if(values.avatar.length < 1){
         setMessage({
           type: 'error',
           text: 'Debes agregar una imagen de perfil',
         });
         setOpen(true);
         return;
      }

      
        if (values.password.length < 6) {
          setMessage({
            type: 'error',
            text: 'Debes ingresar un mínimo de 6 caracteres',
          });
          setOpen(true);
          return;
        }

      if (values.password === '' || values.password !==values.confirmPassword) {
        setMessage({
          type: 'error',
          text: 'Las contraseñas no coinciden',
        });
        setOpen(true);
        return;
      }
           
       dispatch(create(form));
        setMessage({
          type: 'success',
          text: 'Información enviada correctamente!',
        });
        setOpen(true);
        setValues({
          name: '',
          lastName: '',
          position:'',
          email: '',
          mobile: '',
          whatsapp: '',
          username: '',
          password: '',
          confirmPassword: '',
          avatar: '',
        });
        setPreview('');
    } else {
      setMessage({
        type: 'error',
        text: 'Debes validar que no eres un robot ',
      });
      setOpen(true);
    }
  };

   const handleClose = (event, reason) => {
     if (reason === 'clickaway') {
       return;
     }

     setOpen(false);
   };
 const handleCaptchaChange = (token) => {
   setCaptchaValue(token);
 };

  useEffect(() => {

      const delayDebounceFn = setTimeout(() => {
       

        dispatch(validateUsernameExist(values.username)).then((val) => {
          if (val.data === true) {
            setMessage({
              type: 'error',
              text: 'Usuario no disponible, utiliza otro',
            });
            setOpen(true);
            setValidatedUsername(true);
            setIsUsernameTaken(true);
          } else {
            setValidatedUsername(false);
            setIsUsernameTaken(false);
          }
        });
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
   
  }, [values.username]);
  return (
    <Container maxWidth='xs'>
      <Typography variant='h5' component='h1' align='center' sx={{ my: 3 }}>
        Formulario de registro
      </Typography>
      <Stack direction='column' sx={{ mb: 3 }} spacing={2}>
        <Stack direction='row' spacing={2}>
          <TextField
            name='name'
            label='Nombre'
            variant='outlined'
            fullWidth
            required
            value={values.name}
            onChange={handleInputChange}
          />
          <TextField
            name='lastName'
            label='Apellido'
            variant='outlined'
            fullWidth
            required
            value={values.lastName}
            onChange={handleInputChange}
          />
        </Stack>
        <TextField
          name='position'
          label='Cargo'
          variant='outlined'
          fullWidth
          required
          value={values.position}
          onChange={handleInputChange}
        />
        <TextField
          name='email'
          label='Correo'
          variant='outlined'
          fullWidth
          required
          type='email'
          value={values.email}
          onChange={handleInputChange}
        />
        <TextField
          name='mobile'
          label='Movil'
          variant='outlined'
          fullWidth
          required
          type='tel'
          value={values.mobile}
          onChange={handleInputChange}
        />
        <TextField
          name='whatsapp'
          label='WhatsApp'
          variant='outlined'
          fullWidth
          type='tel'
          value={values.whatsapp}
          onChange={handleInputChange}
        />
        <TextField
          name='username'
          label='Usuario'
          variant='outlined'
          fullWidth
          required
          value={values.username}
          onChange={handleInputChange}
        />
        <TextField
          name='password'
          label='Clave'
          variant='outlined'
          fullWidth
          required
          type={showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge='end'
                  aria-label='toggle password visibility'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name='confirmPassword'
          label='Confirmar Clave'
          variant='outlined'
          fullWidth
          required
          type={showPassword ? 'text' : 'password'}
          value={values.confirmPassword}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge='end'
                  aria-label='toggle password visibility'
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <input
          accept='image/*'
          style={{ display: 'none' }}
          id='avatar-upload'
          type='file'
          onChange={handleImageChange}
        />
        <label htmlFor='avatar-upload'>
          <Button variant='outlined' component='span'>
            Cargar foto de perfil
          </Button>
        </label>
        <Avatar
          sx={{ width: 150, height: 150, mx: 'auto' }}
          src={preview}
          alt='Avatar Preview'
        />
        <ReCAPTCHA
          sitekey='6LcTs5MlAAAAAFA4M9INJM6Tds0IY3UZ7s1e7wsl'
          onChange={handleCaptchaChange}
        />
        <Button
          type='submit'
          variant='contained'
          fullWidth
          onClick={handleSubmit}
        >
          Enviar datos
        </Button>
      </Stack>

      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity={message.type}
          sx={{ width: '100%' }}
        >
          {message.text}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default MobileForm;
