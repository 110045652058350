import Url from "../config/SocialUrls";
import { isAndroid, isIOS } from "react-device-detect";


let timeOut = (df, value) => {
  var now = new Date().valueOf();

  setTimeout(function () {
    if (new Date().valueOf() - now > 100) return;
    return (window.location = df);
  }, 2000);
  return window.location.replace(value);
};

const Helper = {
  filterString: function (str) {
    let result = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return result.toLowerCase();
  },
  capitalize: (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  deepLink: (name, phone, email, value, guid) => {
    switch (name) {
      case "Llamar":
        Url.Llamar(phone);
        break;
      case "Correo":
        Url.Correo(email);
        break;
      case "Agregar contacto":
        Url.AC(guid);
        break;
      case "Tarjeta de contacto":
        Url.AC(guid);
        break;

      default:
        return;
    }
  },

  b64toBlob: (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var bb = new Blob([ab], { type: "image/png" });
    return bb;
  },
};

export default Helper;
