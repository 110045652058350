import http from "../../config/axios";
const Token = localStorage.getItem("quiickAdmToken");
const header = {
  Authorization: `Bearer ${JSON.parse(Token)}`,
};
class BusinessService {
  get() {
    return http.get(`/business/`, { headers: header });
  };

  create(data) {
    return http.post(`/business/`, data);
  }
}
export default new BusinessService();
