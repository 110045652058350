
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Logo from '../../assets/logo/svg/black.svg'
import pro from "../../assets/profile/pro.svg";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#FFFFFF',
  border: '2px solid #FFF',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

import { useSelector, useDispatch } from "react-redux";
import {
  setModal,
  setPwd,
  getProfile,
} from "../../redux/profile/profileActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

export default function ModalPwd()  {
const dispatch = useDispatch();
    // const isOpen = useSelector((state) => true);
      const isOpen = useSelector((state) => state.profile.isOpen);
      const referred = useSelector((state) => state.profile.referred);
      const profiles = useSelector((state) => state.profile.profiles);
      const [error, showError] = useState({
        status:false,
        msg:''
      });
       const [pwd, setPwdi] = useState("");
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState({
        type: "",
        text: "",
      });

 const handleClose = (event, reason) => {
  
   if (reason === "clickaway") {
     return;
   }
  dispatch(setModal({ status: false, type: null }));
  showError(false);
 };



 const handleAsign = () => {
    
 if (pwd.length < 6) {
   showError({
     status: true,
     msg: "Debes ingresar un mínimo de 6 caracteres",
   });
   return;
 }
  showError({status:false});
      dispatch(
        setPwd({id:referred?.id,password:pwd })
      ).then((val) => {
         if (val.response === "ok") {
         
           dispatch(setModal({ status: false, type: null }));
            dispatch(getProfile(0));
             setOpen(true);
              setMessage({
                type: "success",
                text: val.data,
              });
         }else{
           setMessage({
             type: "error",
             text: "Ocurrió un error al cambiar la clave el usuario",
           });
           setOpen(true);
         }
      });
      showError(false)
      return
 }

const handleCloseSnack = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setOpen(false);
};
      
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isOpen.type === "pwd" ? isOpen.status : false}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isOpen.type === "pwd" ? isOpen.status : false}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="body2"
                component="p"
                sx={{
                  textAlign: "center",
                  mb: "1rem",
                  fontSize: "16pt",
                }}
              >
                Cambiar clave a
                <b
                  style={{ textTransform: "capitalize" }}
                >{` ${referred?.name} ${referred?.lastName}`}</b>
              </Typography>
              <Divider />

              {/* <Typography
                variant="body2"
                component="p"
                sx={{
                  color: "#6a6a69",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "12pt",
                  mb: "1rem",
                  mt: "1rem",
                }}
              >
                {referred?.blocked
                  ? "Una vez aplicado el desbanneo, el usuario será desbloqueado y podrá registrar o utilizar su correo, teléfono y usuario en Quiick."
                  : "Una vez aplicado el banneo, el usuario será bloqueado y no podrá registrar ni utilizar su correo, teléfono y usuario en Quiick."}
              </Typography> */}
              <TextField
                sx={{ mx: 1, mt: 2 }}
                // margin="normal"
                // required
                variant="standard"
                fullWidth
                name="password"
                defaultValue={pwd}
                label="Nueva clave"
                onChange={(e) => setPwdi(e.target.value.replace(/\s+/g, ""))}
                id="password"
              />
              <Button
                onClick={handleAsign}
                className="btn-full"
                type="button"
                fullWidth
                variant="contained"
                sx={{ mb: 2, my: "1rem" }}
              >
                Cambiar
              </Button>

              <Button
                onClick={handleClose}
                // className="btn-full"
                type="button"
                ariant="outlined"
                color="error"
                sx={{ textAlign: "center", mb: "1rem" }}
              >
                Cancelar
              </Button>

              <Divider />
              {error.status ? (
                <Typography
                  sx={{
                    textAlign: "center",
                    mb: "1rem",
                    // backgroundColor: "#ea3d1f",
                    color: "#ea3d1f",
                    p: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {error.msg}
                </Typography>
              ) : null}
            </Box>
          </Fade>
        </Modal>
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={message.type}
            sx={{ width: "100%" }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      </div>
    );
   
}
