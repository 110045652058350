import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Login from "./pages/auth/login/index";
import ApplicationRoutes from "./config/appRoute";
import SmartBanner from "react-smartbanner";
import "../node_modules/react-smartbanner/dist/main.css";


export default function App() {
  return (
    // <Container maxWidth="sm">
    //   <Box sx={{ my: 4 }}>
    <>
      {/* <SmartBanner
        title={"Quiick"}
        force={"ios"}
        position={"top"}
        button={"Ver"}
        price={{
          ios: "Gratis",
          android: "Gratis",
        }}
        storeText={{
          ios: "Disponible en App Store",
          android: "Disponible en Google Play",
        }}
        url={{
          ios: "http://www.domain.com",
          android: "http://www.domain2.com",
          windows: "http://www.domain3.com",
        }}
        daysHidden={0}
      /> */}
      <ApplicationRoutes />
    </>
    //   </Box>
    // </Container>
  );
}
