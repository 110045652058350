import React, { useCallback, useEffect, useMemo, useState } from "react";
import MaterialReactTable from 'material-react-table';
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Password, AppBlocking, PersonAdd } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
// Layout
import Nav from "../layouts/nav";
import defaultAvatar from "../../assets/profile/default_avatar.svg";
import Pro from "../../components/modalRefer"
import ModalBan from "../../components/modalBan"
import ModalPwd from "../../components/modalPwd";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getProfile, setModal,  setReferred} from "../../redux/profile/profileActions";

const moment = require("moment");
moment.locale("es-do");


const theme = createTheme({
  typography: {
    fontFamily: ['"Helvetica Neue"'].join(","),
  },
});

// const data = [
  // {
  //   firstName: 'Dusty',
  //   lastName: 'Kuvalis',
  //   email: 'Randy63@yahoo.com',
  //   jobTitle: 'Chief Creative Technician',
  //   salary: 52729,
  //   startDate: '3/20/2014',
  //   signatureCatchPhrase: 'Cross-platform disintermediate workforce',
  //   avatar:
  //     'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/106.jpg',
  // },
// ];




export default function Users() {

    const dispatch = useDispatch();
    const profiles = useSelector((state) => state.profile.profiles);
    const onlyUsers = useSelector((state) => state.profile.onlyUsers);
     useEffect(() => {
       if (profiles.length === 0) {
         dispatch(getProfile(0));
       }
     }, []);

 const handleOpen = (data,type) => {
    dispatch(setReferred(data))
    dispatch(setModal({status:true,type:type}));
 }


   
  let data = [];
  let dataUsers = [];

   const columns = useMemo(
     () => [
       {
         id: "Perfil", //id used to define `group` column
         //  header: "Perfil",
         columns: [
           {
             accessorFn: (row) => `${row?.name} ${row?.lastName}`, //accessorFn used to join multiple data into a single cell
             id: "name", //id is still required when using accessorFn instead of accessorKey
             header: "Nombre",
             size: 250,
             Cell: ({ cell, row }) => (
               <>
                 <Box
                   sx={{
                     display: "flex",
                     flexDirection: "row",
                     alignItems: "center",
                     gap: "1rem",
                   }}
                   style={{ textTransform: "capitalize" }}
                 >
                   {/* <img
                   alt="avatar"
                   height={30}
                   src={row.original.avatar}
                   loading="lazy"
                   style={{ borderRadius: "50%" }}
                 /> */}
                   <Typography sx={{ fontWeight: "Bold" }}>
                     {cell.getValue()}
                   </Typography>
                 </Box>
                 <Typography>{row.original.username}</Typography>
               </>
             ),
           },

           {
             accessorKey: "email", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
             enableClickToCopy: true,
             header: "Correo",
             size: 250,
           },
           {
             accessorKey: "plan", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
             header: "Plan",
             size: 100,
             Cell: ({ cell }) => (
               <Box
                 sx={(theme) => ({
                   backgroundColor:
                     cell.getValue() === "Free"
                       ? theme.palette.success.dark
                       : cell.getValue() === "Pro"
                       ? theme.palette.secondary.dark
                       : "#000000",
                   borderRadius: "0.25rem",
                   color: "#fff",
                   maxWidth: "7rem",
                   padding: "1rem",
                   textAlign: "center",
                   p: "0.25rem",
                 })}
               >
                 {cell.getValue()}
               </Box>
             ),
           },
           {
             accessorKey: "ifCard", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
             enableClickToCopy: true,
             header: "QuiickCard",
             size: 150,
           },

           {
             accessorKey: "referred", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
             enableClickToCopy: true,
             header: "Referido por",
             size: 150,
             Cell: ({ cell }) => (
               <Box style={{ textTransform: "capitalize" }}>
                 {cell.getValue()}
               </Box>
             ),
           },

           {
             accessorKey: "created", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
             header: "Registrado en",
             size: 150,
           },
           {
             size: 200,
             header: "Baneado",
             accessorKey: "baneado", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
           },
           {
             accessorKey: "id", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
           },
           {
             accessorKey: "blocked", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
           },
         ],
       },
     ],
     []
   );
    const columnsUsers = useMemo(
      () => [
        {
          id: "Perfil", //id used to define `group` column
          //  header: "Perfil",
          columns: [
            {
              enableClickToCopy: true,
              header: "Correo",
              accessorKey: "email", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            },
            {
              header: "Creado el",
              accessorKey: "created_at", //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
            },
          ],
        },
      ],
      []
    );
    if(profiles.length < 1)
      return (
        <ThemeProvider theme={theme}>
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Nav title="Usuarios" />

            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
              }}
            >
              <Toolbar />

              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
             
                  <Container component="main">
                    <CssBaseline />
                    <Box sx={{ width: "100%", marginTop: 5 }}>
                      <Skeleton />
                      <Skeleton variant="rectangular" height={118} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                      <Skeleton variant="text" />
                    </Box>
                  </Container>
             
              </Container>
            </Box>
          </Box>
        </ThemeProvider>
      );

      // console.log(profiles[0].user.username)
      profiles.map((p) => {
        let refferand = profiles.find((r) => r.user?.id === p.user?.referredBy);
        let refName = refferand?.personal?.name || null;
        let lastName = refferand?.personal?.lastName || null;
        data.push({
          name: p.personal?.name,
          lastName: p.personal?.lastName,
          avatar: p.personal?.avatar || defaultAvatar,
          position: p.business?.position || (
            <Typography sx={{ color: "#ccc" }}>Posición</Typography>
          ),
          bio: p.personal?.bio || (
            <Typography sx={{ color: "#ccc" }}>Bio</Typography>
          ),
          business: p.business?.name || (
            <Typography sx={{ color: "#ccc" }}>Empresa</Typography>
          ),
          username: p?.user?.username || (
            <Typography sx={{ color: "#ccc" }}>Usuario</Typography>
          ),
          plan: p.plan?.planType,
          ifCard: p.card || "No",
          email: p?.user?.email || "Correo",
          referred: refName + " " + lastName || "No",
          created: moment(p.created_at).format("LLL"),
          id: p?.user?.id,
          blocked: p?.user?.blocked,
          baneado:
            p?.user?.blocked === true ? (
              <Typography sx={{ color: "#da3838" }}>
                <RemoveCircleIcon />
              </Typography>
            ) : (
              <Typography sx={{ color: "#a9a7a7" }}>
                <RemoveCircleIcon />
              </Typography>
            ),
        });
      });

      onlyUsers.map(o => {
         dataUsers.push({
          email: o.email,
          created_at: moment(o.created_at).format("LLL")
      });
    });
      
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Nav title="Usuarios" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <MaterialReactTable
                columns={columns}
                data={data}
                enableColumnFilterModes
                enableColumnOrdering
                enableColumnResizing
                enableGrouping
                enablePinning
                enableRowActions
                // enableRowSelection
                initialState={{
                  columnVisibility: { id: false, blocked: false },
                }} //hide firstName column by default
                positionToolbarAlertBanner="bottom"
                renderDetailPanel={({ row }) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="avatar"
                      height={150}
                      src={row.original.avatar}
                      loading="lazy"
                      style={{ borderRadius: "50%" }}
                    />
                    <Box sx={{ textAlign: "center" }}>
                      <Typography variant="h6">Posición:</Typography>

                      <Typography variant="body2">
                        {row.original.position}
                      </Typography>

                      <Typography sx={{ mt: 2 }} variant="body2">
                        <b>Empresa:</b> {row.original.business}
                      </Typography>

                      <Typography sx={{ mt: 2 }} variant="body2">
                        Bio:
                      </Typography>
                      <Typography sx={{ maxWidth: "10rem" }} variant="body2">
                        {row.original.bio}
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderRowActionMenuItems={({ row, closeMenu }) => [
                  <MenuItem
                    key={0}
                    onClick={() => {
                      handleOpen(row.original, "pwd");
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Password />
                    </ListItemIcon>
                    Cambiar clave
                  </MenuItem>,
                  <MenuItem
                    key={1}
                    onClick={() => {
                      handleOpen(row.original, "ban");
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <AppBlocking />
                    </ListItemIcon>
                    {row.original.blocked ? "Desbanear" : "Banear"}
                  </MenuItem>,
                  <MenuItem
                    key={2}
                    onClick={(event) => {
                      //  console.log(row.original.id);
                      handleOpen(row.original, "ref");
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <PersonAdd />
                    </ListItemIcon>
                    Asignar referido
                  </MenuItem>,
                ]}
                renderTopToolbarCustomActions={({ table }) => {
                  const handleDeactivate = () => {
                    table.getSelectedRowModel().flatRows.map((row) => {
                      alert("deactivating " + row.getValue("name"));
                    });
                  };

                  const handleActivate = () => {
                    table.getSelectedRowModel().flatRows.map((row) => {
                      alert("activating " + row.getValue("name"));
                    });
                  };

                  const handleContact = () => {
                    table.getSelectedRowModel().flatRows.map((row) => {
                      alert("contact " + row.getValue("name"));
                    });
                  };

                  return (
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      {/* {table.getSelectedRowModel().flatRows.length} */}
                      {/* <Button
                        color="error"
                        disabled={
                          table.getSelectedRowModel().flatRows.length === 0
                        }
                        onClick={handleDeactivate}
                        variant="contained"
                      >
                        Deactivate
                      </Button> */}
                      {/* <Button
                        color="success"
                        disabled={
                          table.getSelectedRowModel().flatRows.length === 0
                        }
                        onClick={handleActivate}
                        variant="contained"
                      >
                        Activate
                      </Button> */}
                      {/* <Button
                        color="info"
                        disabled={
                          table.getSelectedRowModel().flatRows.length === 0
                        }
                        onClick={handleContact}
                        variant="contained"
                      >
                        Contact
                      </Button> */}
                    </div>
                  );
                }}
              />
            </Paper>
          </Container>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <MaterialReactTable
                columns={columnsUsers}
                data={dataUsers}
                enableColumnFilterModes
                // enableColumnOrdering
                // enableColumnResizing
                // enableGrouping
                // enablePinning
                // enableRowActions
                // enableRowSelection
                // positionToolbarAlertBanner="bottom"

                // renderRowActionMenuItems={({ row, closeMenu }) => [
                //   <MenuItem
                //     key={0}
                //     onClick={() => {
                //       handleOpen(row.original, "pwd");
                //       closeMenu();
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <Password />
                //     </ListItemIcon>
                //     Cambiar clave
                //   </MenuItem>,
                //   <MenuItem
                //     key={1}
                //     onClick={() => {
                //       handleOpen(row.original, "ban");
                //       closeMenu();
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <AppBlocking />
                //     </ListItemIcon>
                //     {row.original.blocked ? "Desbanear" : "Banear"}
                //   </MenuItem>,
                //   <MenuItem
                //     key={2}
                //     onClick={(event) => {
                //       //  console.log(row.original.id);
                //       handleOpen(row.original, "ref");
                //       closeMenu();
                //     }}
                //     sx={{ m: 0 }}
                //   >
                //     <ListItemIcon>
                //       <PersonAdd />
                //     </ListItemIcon>
                //     Asignar referido
                //   </MenuItem>,
                // ]}
                renderTopToolbarCustomActions={({ table }) => {
                 
                  return (
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <Typography
                        variant="h6"
                        sx={{ color: "#000000", fontSize: "18pt" }}
                      >
                        Usuarios fuera de quiick
                      </Typography>
                      {/* {table.getSelectedRowModel().flatRows.length} */}
                    </div>
                  );
                }}
              />
            </Paper>
          </Container>
        </Box>
      </Box>
      <ModalPwd />
      <Pro />
      <ModalBan />
    </ThemeProvider>
  );
}
