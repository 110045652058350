import DashService from "./dashService";

export const getStatics = () => async (dispatch) => {
  try {
    const res = await DashService.get();
   
    dispatch({
      type: "GET",
      payload: res.data,
    });
  //   return Promise.resolve(res.data);
  } catch (err) {
  //   return Promise.reject(err);
  }
};

