
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Logo from '../../assets/logo/svg/black.svg'
import pro from "../../assets/profile/pro.svg";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: '#FFFFFF',
  border: '2px solid #FFF',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

import { useSelector, useDispatch } from "react-redux";
import {
  setModal,
  setReferrands,
  getProfile,
} from "../../redux/profile/profileActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});

export default function ProModal()  {
const dispatch = useDispatch();
    // const isOpen = useSelector((state) => true);
      const isOpen = useSelector((state) => state.profile.isOpen);
      const referred = useSelector((state) => state.profile.referred);
      const profiles = useSelector((state) => state.profile.profiles);
      const [error, showError] = useState(false);
      const [open, setOpen] = useState(false);
      const [message, setMessage] = useState({
        type: "",
        text: "",
      });
 const handleClose = (event, reason) => {
  
   if (reason === "clickaway") {
     return;
   }
  dispatch(setModal({ status: false, type: null }));
  showError(false);
  setSelectedOptions([]);
 };
const [selectedOptions, setSelectedOptions] = useState([]);

const handleChange = (event, value) => setSelectedOptions(value);
 const dataSelected = profiles.filter(
   (p) =>
     !p.user?.referredBy &&
     p.user?.referredBy !== referred?.id &&
     p.user?.id !== referred?.id
 );

 const handleAsign = () => {
      if(selectedOptions.length < 1) return showError(true);

      const ids = selectedOptions.map((option) => option.id);

      dispatch(
        setReferrands({ referred:referred?.id, referrands: ids })
      ).then((val) => {
      
         if (val.response === "ok") {
          setSelectedOptions([]);
           dispatch(setModal({ status: false, type: null }));
            dispatch(getProfile(0));
             setMessage({
               type: "success",
               text: "Asignación establecida",
             });
             setOpen(true);
         }else{
           setMessage({
             type: "error",
             text: "Ocurrió un error al asignar los usuarios",
           });
           setOpen(true);
         }
      });
      showError(false)
      return
 }

const handleCloseSnack = (event, reason) => {
  if (reason === "clickaway") {
    return;
  }
  setOpen(false);
};
      
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={(isOpen.type === "ref" ? isOpen.status : false)}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={(isOpen.type === "ref" ? isOpen.status : false)}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h1"
                sx={{ textAlign: "center", mb: "1rem" }}
              >
                Asignación de referido a{" "}
              </Typography>
              <Divider />

              <Typography
                id="transition-modal-title"
                variant="h5"
                component="h1"
                sx={{
                  textAlign: "center",
                  mb: "1rem",
                  mt: "1rem",
                  backgroundColor: "rgb(0, 0, 0)",
                  color: "#FFFFFF",
                  p: "1rem",
                }}
              >
                {" "}
                {` ${referred?.name} ${referred?.lastName}`}
              </Typography>

              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                onChange={handleChange}
                options={dataSelected}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.user?.email}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      // style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.user?.email}
                  </li>
                )}
                // style={{ width: 500 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Usuarios"
                    placeholder="Selecciona"
                  />
                )}
              />
              <Typography
                variant="body2"
                component="p"
                sx={{
                  color: "#e4b535",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "9pt",
                  mb: "1rem",
                  mt: "1rem",
                }}
              >
                Una ves aplicado el cambio, se notificará al referidor sobre los
                usuarios asignados si estos yá activaron su tarjeta.
              </Typography>

              <Button
                onClick={handleAsign}
                className="btn-full"
                type="button"
                fullWidth
                variant="contained"
                sx={{ mb: 2, my: "1rem" }}
              >
                Asignar
              </Button>

              <Button
                onClick={handleClose}
                // className="btn-full"
                type="button"
                ariant="outlined"
                color="error"
                sx={{ textAlign: "center", mb: "1rem" }}
              >
                Cancelar
              </Button>

              <Divider />
              {error ? (
                <Typography
                  sx={{
                    textAlign: "center",
                    mb: "1rem",
                    // backgroundColor: "#ea3d1f",
                    color: "#ea3d1f",
                    p: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Debes agregar almenos un usuario
                </Typography>
              ) : null}
            </Box>
          </Fade>
        </Modal>
        <Snackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={message.type}
            sx={{ width: "100%" }}
          >
            {message.text}
          </Alert>
        </Snackbar>
      </div>
    );
   
}
