import authService from "./authService";

export const sendEmail = (email) => async (dispatch) => {
  try {
    const res = await authService.sendEmail({ email: email });
    dispatch({
      type: "SEND_EMAIL",
      payload: res.data,
    });
    console.log(res)
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err)
    return Promise.reject(err);
  }
};

export const validateEmailExist = (email) => async (dispatch) => {
  try {
    const res = await authService.validateEmailExist(email);
    dispatch({
      type: "VALIDATE_EMAIL",
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const validateUsernameExist = (username) => async (dispatch) => {
  try {
    const res = await authService.validateUsernameExist(username);
    dispatch({
      type: "VALIDATE_USERNAME",
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const validateEmailCode = (data) => async (dispatch) => {
  try {
    const res = await authService.validateEmailCode({code:data});
    dispatch({
      type: "VALIDATE_EMAILC",
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const register = (data) => async (dispatch) => {
  try {
    const res = await authService.register(data);
    dispatch({
      type: "REGISTER",
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const login = (data) => async (dispatch) => {
  try {
    const res = await authService.login(data);
    dispatch({
      type: "LOGIN",
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
