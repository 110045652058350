import { combineReducers } from "redux";

import authReducer from "./auth/authReducer";
import profileReducer from "./profile/profileReducer";
import iconReducer from "./icon/iconReducer";
import DashReducer from "./dashboard/dashReducer";
import BusinessReducer from './business/businessReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  icon: iconReducer,
  dash: DashReducer,
  business: BusinessReducer
});

export default rootReducer;
