import ProfileService from "./profileService";

export const getProfile = (limit) => async (dispatch) => {
  try {
    const res = await ProfileService.get(limit);
   
    dispatch({
      type: "GET",
      payload: res.data,
    });
  //   return Promise.resolve(res.data);
  } catch (err) {
  //   return Promise.reject(err);
  }
};

export const setModal = (data) => async (dispatch) => {

  dispatch({
    type: "SET_MODAL",
    payload: data,
  });
};

export const setReferred = (data) => async (dispatch) => {


  dispatch({
    type: "SET_REFERRED",
    payload: data,
  });
};

export const setReferrands = (data) => async (dispatch) => {
  try {
    const res = await ProfileService.setReferrands(data);

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }

  // dispatch({
  //   type: "SET_REFERRED",
  //   payload: data,
  // });
};

export const setBan = (data) => async (dispatch) => {
  try {
    const res = await ProfileService.setBan(data);

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const setPwd = (data) => async (dispatch) => {
  try {
    const res = await ProfileService.setPwd(data);

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
