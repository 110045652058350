import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { Password, AppBlocking, PersonAdd } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import Button from "@mui/material/Button";
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
// Layout
import Nav from '../layouts/nav';
import defaultAvatar from '../../assets/profile/default_avatar.svg';
import Pro from '../../components/modalRefer';
import ModalBan from '../../components/modalBan';
import ModalPwd from '../../components/modalPwd';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
  getAll,
} from '../../redux/business/businessActions';

const moment = require('moment');
moment.locale('es-do');

const theme = createTheme({
  typography: {
    fontFamily: ['"Helvetica Neue"'].join(','),
  },
});

// const data = [
// {
//   firstName: 'Dusty',
//   lastName: 'Kuvalis',
//   email: 'Randy63@yahoo.com',
//   jobTitle: 'Chief Creative Technician',
//   salary: 52729,
//   startDate: '3/20/2014',
//   signatureCatchPhrase: 'Cross-platform disintermediate workforce',
//   avatar:
//     'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/106.jpg',
// },
// ];

export default function Users() {
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.business.data);

  useEffect(() => {
    if (profiles.length === 0) {
      dispatch(getAll(0));
    }
  }, []);

  const handleOpen = (data, type) => {
    dispatch(setReferred(data));
    dispatch(setModal({ status: true, type: type }));
  };
   const handleEdit= (data, type) => {
          console.log(data)
   };

  let data = [];

  const columns = useMemo(
    () => [
      {
        id: 'Perfil', //id used to define `group` column
        //  header: "Perfil",
        columns: [
          {
            accessorFn: (row) => `${row?.name} `, //accessorFn used to join multiple data into a single cell
            id: 'name', //id is still required when using accessorFn instead of accessorKey
            header: 'Nombre',
            size: 50,
            disableSortBy: true,

            Cell: ({ cell, row }) => (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '1rem',
                  }}
                  style={{ textTransform: 'capitalize' }}
                >
                  <Typography sx={{ fontWeight: 'Bold' }}>
                    {cell.getValue()}
                  </Typography>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{ fontSize: 14 }}
                    onClick={() => handleEdit(row?.original.action)}
                  >
                    <RemoveRedEyeIcon />
                  </Button>
                </Box>
              </>
            ),
          },
        ],
      },
    ],
    []
  );
  
  if (profiles.length < 1)
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Nav title='Usuarios' />

          <Box
            component='main'
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />

            <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
              <Container component='main'>
                <CssBaseline />
                <Box sx={{ width: '100%', marginTop: 5 }}>
                  <Skeleton />
                  <Skeleton variant='rectangular' height={118} />
                  <Skeleton variant='circular' width={40} height={40} />
                  <Skeleton animation='wave' />
                  <Skeleton animation={false} />
                  <Skeleton variant='text' />
                </Box>
              </Container>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );

  // console.log(profiles[0].user.username)
  profiles.map((p) => {

    data.push({
      name: p.name,
      action: p.id,
  
    });
  });



  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Nav title='Usuarios' />

        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />

          <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
            {/* <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}> */}
            <MaterialReactTable
              columns={columns}
              data={data}
              enableColumnActions={false}
              enableColumnFilters={false}
              enablePagination={false}
              enableSorting={false}
              enableBottomToolbar={false}
              // enableTopToolbar={false}
              muiTableBodyRowProps={{ hover: false }}
              // enableTopToolbar={false}
              // enableColumnFilterModes
              // enableColumnOrdering
              // enableColumnResizing
              // enableGrouping
              // enablePinning
              // enableRowActions
              // enableRowSelection
              initialState={{
                columnVisibility: { id: false, blocked: false },
              }} //hide firstName column by default
              positionToolbarAlertBanner='bottom'
              // renderDetailPanel={({ row }) => (
              //   <Box
              //     sx={{
              //       display: 'flex',
              //       justifyContent: 'space-around',
              //       alignItems: 'center',
              //     }}
              //   >
              //     <img
              //       alt='avatar'
              //       height={150}
              //       src={row.original.avatar}
              //       loading='lazy'
              //       style={{ borderRadius: '50%' }}
              //     />
              //     <Box sx={{ textAlign: 'center' }}>
              //       <Typography variant='h6'>Posición:</Typography>

              //       <Typography variant='body2'>
              //         {row.original.position}
              //       </Typography>

              //       <Typography sx={{ mt: 2 }} variant='body2'>
              //         <b>Empresa:</b> {row.original.business}
              //       </Typography>

              //       <Typography sx={{ mt: 2 }} variant='body2'>
              //         Bio:
              //       </Typography>Actions
              //       <Typography sx={{ maxWidth: '10rem' }} variant='body2'>
              //         {row.original.bio}
              //       </Typography>
              //     </Box>
              //   </Box>
              // )}
              // renderRowActionMenuItems={({ row, closeMenu }) => [
              //   <MenuItem
              //     key={0}
              //     onClick={() => {
              //       handleOpen(row.original, 'pwd');
              //       closeMenu();
              //     }}
              //     sx={{ m: 0 }}
              //   >
              //     <ListItemIcon>
              //       <Password />
              //     </ListItemIcon>
              //     Cambiar clave
              //   </MenuItem>,
              //   <MenuItem
              //     key={1}
              //     onClick={() => {
              //       handleOpen(row.original, 'ban');
              //       closeMenu();
              //     }}
              //     sx={{ m: 0 }}
              //   >
              //     <ListItemIcon>
              //       <AppBlocking />
              //     </ListItemIcon>
              //     {row.original.blocked ? 'Desbanear' : 'Banear'}
              //   </MenuItem>,
              //   <MenuItem
              //     key={2}
              //     onClick={(event) => {
              //       //  console.log(row.original.id);
              //       handleOpen(row.original, 'ref');
              //       closeMenu();
              //     }}
              //     sx={{ m: 0 }}
              //   >
              //     <ListItemIcon>
              //       <PersonAdd />
              //     </ListItemIcon>
              //     Asignar referido
              //   </MenuItem>,
              // ]}
              renderTopToolbarCustomActions={({ table }) => {
                const handleDeactivate = () => {
                  table.getSelectedRowModel().flatRows.map((row) => {
                    alert('deactivating ' + row.getValue('name'));
                  });
                };

                const handleActivate = () => {
                  table.getSelectedRowModel().flatRows.map((row) => {
                    alert('activating ' + row.getValue('name'));
                  });
                };

                const handleContact = () => {
                  table.getSelectedRowModel().flatRows.map((row) => {
                    alert('contact ' + row.getValue('name'));
                  });
                };

                const handleDelete = () => {
                  if (selectedRows.length === 0) {
                    // Eliminar todas las filas
                  } else {
                    selectedRows.forEach((row) => {
                      // Eliminar la fila seleccionada
                    });
                  }
                };

                return (
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    {/* {table.getSelectedRowModel().flatRows.length} */}
                    {/* <Button
                      onClick={handleDelete}
                      variant='contained'
                      color='secondary'
                      disabled={
                        table.getSelectedRowModel().flatRows.length === 0
                      }
                    >
                      {table.getSelectedRowModel().flatRows.length === 0
                        ? 'Eliminar todas las filas'
                        : `Eliminar ${
                            table.getSelectedRowModel().flatRows.length
                          } fila(s)`}
                    </Button> */}
                    {/* <Button
                        color="error"
                        disabled={
                          table.getSelectedRowModel().flatRows.length === 0
                        }
                        onClick={handleDeactivate}
                        variant="contained"
                      >
                        Deactivate
                      </Button> */}
                    {/* <Button
                        color="success"
                        disabled={
                          table.getSelectedRowModel().flatRows.length === 0
                        }
                        onClick={handleActivate}
                        variant="contained"
                      >
                        Activate
                      </Button> */}
                    {/* <Button
                        color="info"
                        disabled={
                          table.getSelectedRowModel().flatRows.length === 0
                        }
                        onClick={handleContact}
                        variant="contained"
                      >
                        Contact
                      </Button> */}
                  </div>
                );
              }}
            />
            {/* </Paper> */}
          </Container>
        </Box>
      </Box>
      {/* <ModalPwd />
      <Pro />
      <ModalBan /> */}
    </ThemeProvider>
  );
}
