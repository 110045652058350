import * as actionTypes from "./profileTypes";

const INITIAL_STATE = {
  profiles: [],
  onlyUsers:[],
  isOpen: {
    status:false,
    type:null
  },

  referred: null,
};

const ProfileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...state,
        profiles: action.payload.data.profiles,
        onlyUsers: action.payload.data.onlyUsers
      };

    case actionTypes.SET_MODAL:
      return {
        ...state,
        isOpen: action.payload,
      };
    case actionTypes.SET_REFERRED:
      return {
        ...state,
        referred: action.payload,
      };

    default:
      return state;
  }
};

export default ProfileReducer;
