import React, { useContext, useState } from "react";
import useToken from "../components/app/useToken";
import IdleTimerContainer from "../components/app/IdleTimerContainer";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";

//Pages
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard";
import Users from "../pages/users";
import Business from '../pages/business';
import FormBusiness from '../pages/form';



const PrivateRoute = ({
  isAllowed,
  redirectPath = "/auth/login",
  children,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const ApplicationRoutes = () => {
  const token = localStorage.getItem("quiickAdmToken");
 

  return (
    <Router>
      <Routes>
        <Route
          index
          path='/'
          element={!!token ? <Navigate to='/dashboard' /> : <Login />}
        />
        <Route path='/form/:id' element={<FormBusiness />} />

        <Route element={<PrivateRoute isAllowed={!!token} />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/users' element={<Users />} />
          <Route path='/business' element={<Business />} />
        </Route>
        {/* <Route
          path="admin"
          element={
            <ProtectedRoute
              redirectPath="/home"
              isAllowed={!!user && user.roles.includes("admin")}
            >
              <Admin />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path='*'
          element={!!token ? <Navigate to='/dashboard' /> : <Navigate to='/' />}
        />
      </Routes>
    </Router>
  );
};

export default ApplicationRoutes;
