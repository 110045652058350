import { isAndroid, isIOS } from "react-device-detect";

let timeOut = (df,value) => {
    var now = new Date().valueOf();

     setTimeout(function () {
       if (new Date().valueOf() - now > 100) return;
       return window.location = df;
     }, 2000);
      return window.location.replace(value);
}

const Url = {
  QuiickProfile: (value) => {
    return `${process.env.REACT_APP_FRONTEND_URL}/${value}`;
    // let link;
    // let df = `${process.env.REACT_APP_FRONTEND_URL}/${value}`;
    // if (isIOS) {
    //   link = `quiick://${value}`;
    // } else if (isAndroid) {
    //   link = `intent://www.app.quiick.do/${value}/#Intent;package=futr.quickconnect;scheme=https;end`;
    // } else {
    //   return `${process.env.REACT_APP_FRONTEND_URL}/${value}`;
    // }
    // var now = new Date().valueOf();

    // setTimeout(function () {
    //   if (new Date().valueOf() - now > 100) return;
    //   return;
    // }, 2000);
    // return window.location.replace(link);
  },

  QuiickRef: (value) => {
     return `${process.env.REACT_APP_FRONTEND_URL}/${value}`;
    // let link;
    // let df = `${process.env.REACT_APP_FRONTEND_URL}/ref/${value}`;
    // if (isIOS) {
    //   link = `quiick://ref/${value}`;
    // } else if (isAndroid) {
    //   link = `intent://www.app.quiick.do/ref/${value}/#Intent;package=futr.quickconnect;scheme=https;end`;
    // } else {
    //   return `${process.env.REACT_APP_FRONTEND_URL}/${value}`;
    // }
    // var now = new Date().valueOf();

    // setTimeout(function () {
    //   if (new Date().valueOf() - now > 100) return;
    //   return;
    // }, 2000);
    // return window.location.replace(link);
  },

  Instagram: (value) => {
    let link;
    let df = `https://instagram.com/${value}`;
    
    if (isIOS) {
      link = `instagram://user?username=${value}`;
    } else if (isAndroid) {
      link = `intent://www.instagram.com/${value}/#Intent;package=com.instagram.android;scheme=https;end`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Facebook: (value) => {
    let link;
    let df = `https://facebook.com/${value}`;
    if (isIOS) {
      link = `fb://profile/${value}`;
    } else if (isAndroid) {
      link = `intent://profile/${value}#Intent;package=com.facebook.katana;scheme=fb;end`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Twitter: (value) => {
    let link;
    let df = `https://twitter.com/${value}`;
    if (isIOS) {
      link = `twitter://user?screen_name=${value}`;
    } else if (isAndroid) {
      link = `intent://twitter.com/${value}#Intent;package=com.twitter.android;scheme=https;end`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Whatsapp: (value) => {
    let link;
    let df = `https://api.whatsapp.com/send/?phone=${value}&text&app_absent=0`;
    if (isIOS) {
      link = `whatsapp://send?phone=${value}&text&app_absent=0`;
    } else if (isAndroid) {
      link = `intent://send?phone=${value}&text=#Intent;package=com.whatsapp;scheme=whatsapp;end&phone=${value}&text&app_absent=0`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Linkedin: (value) => {
    let link;
    let df = `https://www.linkedin.com/in/${value}`;
    if (isIOS) {
      link = `linkedin://in/${value}`;
    } else if (isAndroid) {
      link = `intent://www.linkedin.com/in/${value}/#Intent;package=com.linkedin.android;scheme=https;end`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Youtube: (value) => {
    let link;
    let df = `https://www.youtube.com/channel/${value}`;
    if (isIOS) {
      link = `vnd.youtube://www.youtube.com/channel/${value}`;
    } else if (isAndroid) {
      link = `intent://www.youtube.com/channel/${value}#Intent;package=com.google.android.youtube;scheme=https;end`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Snapshat: (value) => {
    let link;
    let df = `https://www.snapchat.com/add/${value}`;
    if (isIOS) {
      link = `snapchat://add/${value}`;
    } else if (isAndroid) {
      link = `intent://add/${value}#Intent;scheme=snapchat;package=com.snapchat.android;end;
`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },

  Tiktok: (value) => {
    let link;
    let df = `https://www.tiktok.com/@${value}`;
    if (isIOS) {
      link = `snssdk1233://user/profile/@${value}`;
    } else if (isAndroid) {
      link = `intent://user/profile/@${value}#Intent;package=com.zhiliaoapp.musically;scheme=snssdk1233;end`;
    } else {
      link = df;
    }
    timeOut(df, link);
  },
  Maps: (value) => {
    let link = `https://www.google.com.do/maps/@${value},19z?hl=es`;
    // let df = `https://www.google.com.do/maps/@${value},19z?hl=es`;
    // if (isIOS) {
    //   link = `https://www.google.com.do/maps/@${value},19z?hl=es`;
    // } else if (isAndroid) {
    //   link = `https://www.google.com.do/maps/@${value},19z?hl=es`;
    // } else {
    //   link = df;
    // }
    timeOut(df, link);
  },

  Llamada: (value) => {
    return (window.location = `tel:${value}`);
  },
  Llamar: (value) => {
    value.replace(/(\+1)?[^0-9.\,]./g, '')
    return (window.location = `tel:+1${value}`);
  },

  Correo: (value) => {
    return (window.location = `mailto:${value}`);
  },

  AC: (value) => {
    let fileURL = `${process.env.REACT_APP_BACKEND_URL}/profiles/get-vcard/${value}`;

    return (window.location = fileURL);
  },
};

export default Url;
