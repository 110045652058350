import React, { useEffect, useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
// Layout
import Nav from "../layouts/nav";
import Header from "../layouts/header";
import "./style.css";
import logoAmba from "../../assets/dash/logo_ambasador.png";
import pro from "../../assets/profile/pro.svg";
import BarSerie from "../../components/charts/barSerie";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getStatics } from "../../redux/dashboard/dashActions";


const theme = createTheme({
  typography: {
    fontFamily: ['"Helvetica Neue"'].join(","),
  },
});



ChartJS.register(ArcElement, Tooltip, Legend);

// export 

export default function Dashboard() {
  const dispatch = useDispatch();
  const dash = useSelector((state) => state.dash.data);
//   const dash = useSelector((state) => state.profile.logs);
//   const [open, setOpen] = React.useState(false);
//   const [message, setMessage] = React.useState({
//     type: "",
//     text: "",
//   });

//   function getPrice(num) {
//     if (!num) return Number(0).toFixed(2);
//     if (num < 1) return num.toFixed(2);
//     return (num / 100).toFixed(2);
//   }

  useEffect(() => {
    if (dash.length < 1) {
      dispatch(getStatics());
    }
  }, []);

   if (dash.length < 1)
     return (
       <ThemeProvider theme={theme}>
         <Box sx={{ display: "flex" }}>
           <CssBaseline />
           <Nav title="Usuarios" />

           <Box
             component="main"
             sx={{
               backgroundColor: (theme) =>
                 theme.palette.mode === "light"
                   ? theme.palette.grey[100]
                   : theme.palette.grey[900],
               flexGrow: 1,
               height: "100vh",
               overflow: "auto",
             }}
           >
             <Toolbar />

             <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
               <Container component="main">
                 <CssBaseline />
                 <Box sx={{ width: "100%", marginTop: 5 }}>
                   <Skeleton />
                   <Skeleton variant="rectangular" height={118} />
                   <Skeleton variant="circular" width={40} height={40} />
                   <Skeleton animation="wave" />
                   <Skeleton animation={false} />
                   <Skeleton variant="text" />
                 </Box>
               </Container>
             </Container>
           </Box>
         </Box>
       </ThemeProvider>
     );

const data = {
  labels: ["En Quiick", "Fuera de Quiick"],
  datasets: [
    {
      label: "# of Votes",
      data: [dash?.profiles, dash?.onlyUsers],
      backgroundColor: ["rgba(0, 0, 0, 0.8)", "rgba(0, 0, 0, 0.2)"],
      borderColor: ["rgba(0, 0, 0, 0.2)", "rgba(0, 0, 0, 0.2)"],
      borderWidth: 1,
    },
  ],
};

const dataCards = {
  labels: ["Disponible", "En uso"],
  datasets: [
    {
      label: "# of Votes",
      data: [dash?.freeCards, dash?.usedCards],
      backgroundColor: ["rgba(34, 85, 124, 0.8)", "rgba(0, 0, 0, 0.2)"],
      borderColor: ["rgba(34, 85, 124, 0.2)", "rgba(0, 0, 0, 0.2)"],
      borderWidth: 1,
    },
  ],
};

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Nav title="Dashboard" />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}

              {/* <Grid item xs={6} md={2} lg={2}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column",
                    height: 150,
                  }}
                >
                  <h4>Íconos</h4>
                  <Typography
                    id="transition-modal-title"
                    variant="body2"
                    component="p"
                    sx={{
                      textAlign: "center",
                      fontSize: "25pt",
                    }}
                  >
                    7
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    mt: 2,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 150,
                  }}
                ></Paper>
              </Grid> */}

              <Grid item xs={6} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    // height: 400,
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="body2"
                    component="p"
                    sx={{
                      textAlign: "center",
                      fontSize: "30pt",
                      fontWeight: "bold",
                    }}
                  >
                    {dash?.users}
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h1"
                    sx={{
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    Usuarios
                  </Typography>

                  {/* <Divider/> */}
                  <Pie data={data} />
                  <h2> </h2>
                </Paper>
              </Grid>

              <Grid item xs={6} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    // height: 400,
                  }}
                >
                  <Typography
                    id="transition-modal-title"
                    variant="body2"
                    component="p"
                    sx={{
                      textAlign: "center",
                      fontSize: "25pt",
                      fontWeight: "bold",
                    }}
                  >
                    {dash?.cards}
                  </Typography>
                  <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h1"
                    sx={{
                      textAlign: "center",
                      mb: 2,
                    }}
                  >
                    Quiick Cards
                  </Typography>
                  <Pie data={dataCards} />
                </Paper>
              </Grid>

              <Grid item xs={6} md={2} lg={2}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column",
                    height: 150,
                  }}
                >
                  <h4>Íconos</h4>
                  <Typography
                    id="transition-modal-title"
                    variant="body2"
                    component="p"
                    sx={{
                      textAlign: "center",
                      fontSize: "30pt",
                      fontWeight: "bold",
                    }}
                  >
                    {dash?.icons}
                  </Typography>
                </Paper>
                {/* <Paper
                  sx={{
                    mt: 2,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: 150,
                  }}
                ></Paper> */}
              </Grid>

              {/* Recent Orders */}
              {/* <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  Muy pronto chart de registros diarios entre otras métricas
                  requeridas...
                </Paper>
              </Grid> */}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
