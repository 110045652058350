import http from "../../config/axios";
const Token = localStorage.getItem("quiickAdmToken");
const header = {
  Authorization: `Bearer ${JSON.parse(Token)}`,
};
class ProfileService {
  get(limit) {
    return http.get(`/users?limit=${limit}`, { headers: header });
  }
  setReferrands(data) {

    return http.put(`/users/set-referred`, data ,  { headers: header });
  }
  setBan(id) {
    return http.put(`users/ban/${id}`, {}, { headers: header });
  }
  setPwd(data) {
    return http.post(`users/change-pwd`,data,{ headers: header });
  }
}
export default new ProfileService();
