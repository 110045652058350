export const GET = "GET";
export const CREATE = 'CREATE';
export const GET_ONEPUBLIC = "GET_ONEPUBLIC";
export const GET_LOGS = "GET_LOGS";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_SAVE = "SET_SAVE";
export const SET_MODAL = "SET_MODAL";
export const UPDATE_ICONS = "UPDATE_ICONS";
export const SET_REFERRED = "SET_REFERRED";

