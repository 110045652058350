import BusinessService from './businessService';

export const getAll = () => async (dispatch) => {
  try {
    const res = await BusinessService.get();
   
    dispatch({
      type: "GET",
      payload: res.data,
    });
  //   return Promise.resolve(res.data);
  } catch (err) {
  //   return Promise.reject(err);
  }
};

export const create = (data) => async (dispatch) => {
  try {
    const res = await BusinessService.create(data);

    // dispatch({
    //   type: 'CREATE',
    //   payload: res.data,
    // });
      return Promise.resolve(res.data);
  } catch (err) {
    //   return Promise.reject(err);
  }
};

