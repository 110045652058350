import * as actionTypes from "./businessTypes";

const INITIAL_STATE = {
  data: [],
  isOpen: {
    status:false,
    type:null
  },
};

const DashReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...state,
        data: action.payload.data,
      
      };

   

    default:
      return state;
  }
};

export default DashReducer;
