import http from "../../config/axios";
const Token = localStorage.getItem("quiickAdmToken");
const header = {
  Authorization: `Bearer ${JSON.parse(Token)}`,
};
class DashService {
  get() {
    return http.get(`/users/counts`, { headers: header });
  }
 
}
export default new DashService();
